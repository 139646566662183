import React from 'react';
import Data from '../Data.json';
import YouTubeClip from './common/YouTubeClip.js';

const Landing = () => {

  const pathName = window.location.pathname;

  let res = pathName.substr(1);

  res = res.substring(0, res.length - 1);

  if (res === '') res = 'main';

  const pageData = Data.filter(data => {
    return data.type.toLowerCase().match(res);
  });

  return (
    <div className="row">
      {pageData.map(youtubeClip => {

        return (
          <YouTubeClip
            key={youtubeClip.clipId}
            name={youtubeClip.name}
            clipId={youtubeClip.clipId}
          />
        );
      })}
    </div>
  );
};

export default Landing;
