import React from 'react';

const YouTubeClip = ({ name, clipId }) => {
  const link = 'https://www.youtube.com/embed/' + clipId;

  return (
    <div>
      <br />
      <br />
      <h3>{name}</h3>
      <br />
      <br />

      <div align="center">
        <iframe
          title={name}
          key={clipId}
          width="853"
          height="480"
          src={link}
          frameBorder="0"
          allowFullScreen="true"
        />
      </div>
    </div>
  );
};

export default YouTubeClip;
