import React, { Component } from 'react';

import Header from './components/layout/Header.js';
import Navbar from './components/layout/Navbar.js';
import Footer from './components/layout/Footer.js';

import Landing from './components/Landing.js';

class App extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <Header className="Header" />
          <Navbar className="Navbar" />
          <Landing className="Landing" />
        </div>

        <Footer className="Footer" />
      </div>
    );
  }
}

export default App;
