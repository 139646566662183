import React from 'react';

const Navbar = () => {
  return (
    <ul className="nav">
      <li>
        <a href="/">Главная</a> || &nbsp;
        <a href="/90x-eng/">90x (ENG)</a> || &nbsp;
        <a href="/00x-eng/">00x (ENG)</a> || &nbsp;
        <a href="/selo/">Сельская дискотека</a> || &nbsp;
        <a href="/electro/">Электронщина</a> || &nbsp;
        <a href="/rus-popsa/">Русская попса</a> || &nbsp;
        <a href="/rus-rock-pops/">Русский Рокопопс</a> || &nbsp;
        <a href="/aria/">Ария | Кипелов</a> || &nbsp;
        <a href="/kish/">КИШ</a> || &nbsp;
        <a href="/kalevala/">Калевала</a> || &nbsp;
        <a href="/classic/">Классика</a> || &nbsp;
        <a href="/heavy/">Потяжелее</a> || &nbsp;
        <a href="/music/">Музыка</a> || &nbsp;
        <a href="/funny/">Веселье</a> || &nbsp;
        <a href="/sober/">Tool Sober</a> || &nbsp;
        <a href="/latino/">Латино</a>
      </li>
    </ul>
  );
};

// <a href="/politota/">Политота</a> || &nbsp;
export default Navbar;
