import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-white mt-5 p-4 text-center">
      <br />
      Дискотека {new Date().getFullYear()}
      <br />
      Для контактов:
      <br />
      <img src="https://programmist.net/img/a3333333mail.gif" alt="Marley" />
    </footer>
  );
};

export default Footer;
